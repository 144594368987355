import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumb",
      "style": {
        "position": "relative"
      }
    }}>{`Breadcrumb`}</h1>
    <p>{`O breadcrumb, em tradução literal "migalhas de pão", auxilia os usuários a visualizar a localização atual em um nível de navegação hierárquico e permite que se movam rapidamente para um nível pai.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Posicione o breadcrumb próximo ao topo da página.`}</li>
      <li parentName="ul">{`O `}<em parentName="li">{`breadcrumb`}</em>{` deve mostrar a hierarquia das interfaces e não o histórico de navegação.`}</li>
      <li parentName="ul">{`Mantenha os títulos curtos e descritivos.`}</li>
      <li parentName="ul">{`Não utilize `}<em parentName="li">{`breadcrumbs`}</em>{` para um processo com várias etapas.`}</li>
      <li parentName="ul">{`Somente utilize o componente quando existirem dois ou mais níveis de navegação (Ex.: Home > Configurações).`}</li>
    </ul>
    <Demo src='pages/components/breadcrumb/Breadcrumb' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      